import React from 'react';
import { 
    Text
} from '@chakra-ui/react'

 
const Main = () => {
    return (
        <>
          <Text>Sup</Text>
        </>
    );
}
 
export default Main;