import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
 
import Main from './pages/main';
 
class App extends Component {
  render() {
    return (      
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />} />
          </Routes>
      </BrowserRouter>
    );
  }
}
 
export default App;